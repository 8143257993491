// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC7miHOhNmcid6ii0vWiYmaHDac-bWoles",
  authDomain: "food-ecommerce-40509.firebaseapp.com",
  projectId: "food-ecommerce-40509",
  storageBucket: "food-ecommerce-40509.appspot.com",
  messagingSenderId: "509692774971",
  appId: "1:509692774971:web:7d4eb3848d8f4f258c6a1e"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);

